import React from 'react';
import { Link, graphql } from 'gatsby';
import * as Accordion from '@radix-ui/react-accordion';

import { Layout } from '@/layout/Layout';
import Seo from '../components/common/Seo';
import Group from '@/assets/images/Group22.png';
import RightArrow from '../assets/icons/right-arrow-long-green.inline.svg';
import DownArrow from '../assets/icons/arrow-down.inline.svg';

import imageQuestions from '@/assets/images/image_questions.png';

const PreguntasFrecuentes = ({ data }) => {
  const { frequent_questions } = data.directus;

  return (
    <Layout pageId="question" isHeaderTransparent={true} headerWhiteText={true}>
      <section
        className="h-[280px] bg-center pt-[120px] md:h-[324px]  md:pt-[140px]"
        style={{ backgroundImage: `url(${imageQuestions})` }}
      >
        <h1 className="w-full text-center text-[32px] font-bold leading-10 text-white md:text-6xl">
          Preguntas frecuentes
        </h1>
      </section>

      <section className="bg-[#F5F5F5] py-8 md:pb-24 md:pt-14">
        <div className="container">
          <div className="row">
            <Accordion.Root className="mx-auto md:col-10 md:divide-y-2" type="single" collapsible>
              {frequent_questions.map((question) => (
                <Accordion.Item key={question.id} value={question.id} className="py-5 md:px-14">
                  <Accordion.Header>
                    <Accordion.Trigger className="flex w-full items-center justify-between gap-6 text-left font-bold">
                      {question.title}
                      <DownArrow className="shrink-0 fill-[#303030]" />
                    </Accordion.Trigger>
                  </Accordion.Header>
                  <Accordion.Content className="mt-4">
                    <div
                      className="prose max-w-none prose-p:font-light prose-p:text-[#697276]"
                      dangerouslySetInnerHTML={{ __html: question.body }}
                    />
                  </Accordion.Content>
                </Accordion.Item>
              ))}
            </Accordion.Root>
          </div>
        </div>
      </section>

      <section className="py-8 md:pb-24 md:pt-14">
        <div className="container">
          <div className="row justify-center">
            <div className="mb-4 md:col-5 md:mb-0">
              <img src={Group} alt="" />
            </div>
            <div className="md:col-5">
              <h3 className="text-xl font-bold md:text-3xl">
                ¿Necesitas descargar algún documento o manual de tu equipo nuevo?
              </h3>
              <Link className="my-3 flex items-center gap-2 font-bold text-[#00AAA6]" to="/manuales-productos">
                Ir a soporte
                <RightArrow />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const Head = () => <Seo title="Preguntas Frecuentes | Hisense" />;

export const query = graphql`
  query QuestionsPageQuery {
    directus {
      frequent_questions {
        id
        title
        body
      }
    }
  }
`;

export default PreguntasFrecuentes;
